import DataLayerHelper from './Datalayer';
import { SyntheticEvent } from 'react';
import RetailBooking from '@/components/Retail/Booking/RetailBooking';

export const functions: {
  [key: string]: (
    _e: SyntheticEvent<MouseEvent>,
    _actions: { setDrawer?: any },
  ) => void;
} = {
  retailBooking: () => {
    DataLayerHelper.retailBookingInitEvent();
  },
  retailBookingDrawer: (e, { setDrawer }) => {
    e?.preventDefault();
    setDrawer((prev: any) => ({
      ...prev,
      opened: true,
      withCloseButton: true,
      // @ts-ignore
      body: (
        <RetailBooking
          setDrawer={setDrawer}
          onClose={() => {
            setDrawer((prev: any) => ({ ...prev, opened: false }));
          }}
          shop={'PARIS_COMMERCE'}
          disabledSelectShop
        />
      ),
    }));
  },
  bookingParisCommerce: (e, { setDrawer }) => {
    DataLayerHelper.retailBookingInitEvent('paris-commerce');

    e?.preventDefault();
    setDrawer((prev: any) => ({
      ...prev,
      opened: true,
      withCloseButton: true,
      // @ts-ignore
      body: (
        <RetailBooking
          setDrawer={setDrawer}
          onClose={() => {
            setDrawer((prev: any) => ({ ...prev, opened: false }));
          }}
          shop={'PARIS_COMMERCE'}
          disabledSelectShop
        />
      ),
    }));
  },
  bookingWaterloo: (e, { setDrawer }) => {
    DataLayerHelper.retailBookingInitEvent('waterloo');
    e?.preventDefault();
    setDrawer((prev: any) => ({
      ...prev,
      opened: true,
      withCloseButton: true,
      // @ts-ignore
      body: (
        <RetailBooking
          setDrawer={setDrawer}
          onClose={() => {
            setDrawer((prev: any) => ({ ...prev, opened: false }));
          }}
          shop={'WATERLOO'}
          disabledSelectShop
        />
      ),
    }));
  },
  bookingNamur: (e, { setDrawer }) => {
    DataLayerHelper.retailBookingInitEvent('namur');
    e?.preventDefault();
    setDrawer((prev: any) => ({
      ...prev,
      opened: true,
      withCloseButton: true,
      // @ts-ignore
      body: (
        <RetailBooking
          setDrawer={setDrawer}
          onClose={() => {
            setDrawer((prev: any) => ({ ...prev, opened: false }));
          }}
          shop={'NAMUR'}
          disabledSelectShop
        />
      ),
    }));
  },
};
