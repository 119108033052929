import { IColor } from '@/@types/generated/contentful';
import { MantineTheme, useMantineTheme } from '@mantine/core';
import Size from '@/helpers/Size';
import { NoozIconProps } from '@/interface.custom';
import {
  CalendarPlus,
  Heart,
  Plus,
  CircleMinus,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  ArrowRight,
  Moon,
  X,
  Camera,
  ShoppingBag,
  World,
  ShoppingCartPlus,
  Trash,
  RosetteNumber1,
  RosetteNumber2,
  RosetteNumber3,
  RosetteNumber4,
  RosetteNumber5,
  RosetteNumber6,
  RosetteNumber7,
  RosetteNumber8,
  RosetteNumber9,
  DeviceDesktopAnalytics,
  Check,
  Circle,
  RectangleVertical,
  Oval,
  SquareRotated,
  CircleLetterS,
  CircleLetterM,
  CircleLetterL,
  BuildingStore,
  Video,
  PlayerPlay,
  Search,
  GenderMale,
  GenderFemale,
  Key,
  Phone,
  PhoneCall,
  Mail,
  ShoppingCart,
  Icon,
  HeartPlus,
  Leaf,
  BrandFacebook,
  BrandYoutube,
  BrandInstagram,
  BrandTiktok,
  ArrowsExchange,
  Notes,
  News,
  Butterfly,
  EyeglassOff,
  ScanEye,
  EyeTable,
  Eyeglass,
  User,
  Login,
  Shield,
  Diamond,
  TriangleInverted,
  Rectangle,
  Square,
  CubeSend,
  Help,
  CreditCard,
  Track,
  Map2,
  Feather,
  Flare,
  Sunset2,
  BellRinging,
  ZoomQuestion,
  ShieldCheck,
  Devices,
  CheckupList,
  Book,
  AddressBook,
  ListDetails,
  Logout,
  At,
  ListCheck,
  CircleCheck,
  Quote,
  PlayerPause,
  VolumeOff,
  Volume,
  Hammer,
  Trophy,
  ViewportNarrow,
  HeartHandshake,
  Photo,
  Download,
  Wand,
  Discount,
  BrandWhatsapp,
  DirectionSign,
  Star,
  StarHalf,
  History,
  InfoCircle,
  Stack2,
  Stack,
  Selector,
  Package,
  PackgeImport,
  MapPin,
  Crown,
  UserCircle,
  Sunglasses,
  CalendarEvent,
  ClockHour7,
  TruckDelivery,
  Tags,
} from 'tabler-icons-react';
import { useMemo } from 'react';

const DYNAMIC_ICONS: Record<string, Icon> = {
  Package,
  Tags,
  UserCircle,
  Sunglasses,
  CalendarEvent,
  ClockHour7,
  MapPin,
  Crown,
  PackgeImport,
  CalendarPlus,
  InfoCircle,
  Stack2,
  Stack,
  Discount,
  ArrowRight,
  ArrowsExchange,
  BellRinging,
  Book,
  BrandFacebook,
  BrandInstagram,
  BrandTiktok,
  BrandYoutube,
  BuildingStore,
  Butterfly,
  Camera,
  Check,
  CheckupList,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Circle,
  CircleLetterL,
  CircleLetterM,
  CircleLetterS,
  CircleMinus,
  CreditCard,
  CubeSend,
  DeviceDesktopAnalytics,
  Devices,
  Diamond,
  Eyeglass,
  EyeglassOff,
  EyeTable,
  Feather,
  Flare,
  GenderFemale,
  GenderMale,
  Heart,
  Help,
  Key,
  Leaf,
  Login,
  Mail,
  Map2,
  News,
  Notes,
  Oval,
  Phone,
  PhoneCall,
  PlayerPlay,
  Plus,
  Rectangle,
  RectangleVertical,
  RosetteNumber1,
  RosetteNumber2,
  RosetteNumber3,
  RosetteNumber4,
  RosetteNumber5,
  RosetteNumber6,
  RosetteNumber7,
  RosetteNumber8,
  RosetteNumber9,
  ScanEye,
  Search,
  HeartPlus,
  Shield,
  ShieldCheck,
  ShoppingBag,
  ShoppingCart,
  ShoppingCartPlus,
  Square,
  SquareRotated,
  Sunset2,
  Track,
  Trash,
  TriangleInverted,
  User,
  Video,
  World,
  X,
  ZoomQuestion,
  AddressBook,
  ListDetails,
  Logout,
  At,
  ListCheck,
  CircleCheck,
  Quote,
  PlayerPause,
  VolumeOff,
  Volume,
  Hammer,
  Trophy,
  ViewportNarrow,
  HeartHandshake,
  Photo,
  Download,
  Wand,
  BrandWhatsapp,
  Moon,
  DirectionSign,
  Star,
  StarHalf,
  History,
  Selector,
  TruckDelivery,
};

const generateIconConfig = (theme: MantineTheme, config: NoozIconProps) => ({
  color: (config?.color ||
    (config?.color as IColor)?.fields?.hex ||
    theme.black) as string,
  strokeWidth: config?.strokeWidth || 1.5,
  size: Size.convertFromCf(config.size || 16),
});

const NoozIcon = ({ iconKey, className, ...props }: NoozIconProps) => {
  const theme = useMantineTheme();

  const SelectedComponent = useMemo(
    () => (iconKey && DYNAMIC_ICONS[iconKey] ? DYNAMIC_ICONS[iconKey] : null),
    [iconKey],
  );
  if (!SelectedComponent) return null;

  return (
    <SelectedComponent
      className={className}
      {...generateIconConfig(theme, props)}
    />
  );
};

export default NoozIcon;
