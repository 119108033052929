import {
  ActionIcon,
  Container,
  Flex,
  Group,
  Stepper,
  LoadingOverlay,
  Box,
} from '@mantine/core';
import React from 'react';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import useStaticContent from '@/utils/hook/useStaticContent';
import connectBooking, {
  RetailBookingProps,
  SHOP,
} from '@/components/Retail/Booking/RetailOnBookingHoc';
import RetailBookingStepResult from '@/components/Retail/Booking/RetailBookingStepResult';
import RetailBookingStepInfo from '@/components/Retail/Booking/RetailBookingStepInfo';
import RetailBookingStepType from '@/components/Retail/Booking/RetailBookingStepType';
import RetailBookingStepDate from '@/components/Retail/Booking/RetailBookingStepDate';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import RetailBookingStepShop from '@/components/Retail/Booking/RetailBookingStepShop';

const stepperStyles: any = {
  root: {
    width: '100%',
    maxWidth: 750,
    margin: '0 auto',
    height: '100%',
  },
  steps: {
    alignItems: 'center !important',
    // @ts-ignore
    flexDirection: 'row !important',
  },
  separator: {
    width: 'unset !important',
    minHeight: 'unset !important',
    margin: '0 1rem !important',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

const SHOPS: Record<SHOP, string> = {
  PARIS_COMMERCE: 'Paris Commerce',
  WATERLOO: 'Waterloo',
  NAMUR: 'Namur',
};
const RetailBooking = ({
  onSubmit,
  setForm,
  setActive,
  data,
  loading,
  form,
  isCurrentDay,
  active,
  onClose,
  shop,
  setShop,
  disabledSelectShop,
}: RetailBookingProps) => {
  const staticContent = useStaticContent();

  return (
    <Container>
      {onClose ? (
        <Group
          position={'right'}
          mb={8}>
          <ActionIcon
            variant={'outline'}
            radius={'xl'}
            onClick={onClose}>
            <NoozIcon iconKey={'X'} />
          </ActionIcon>
        </Group>
      ) : (
        <>
          {shop ? (
            <NoozText
              size={24}
              mb={32}
              align={'center'}>
              {(staticContent.yourShopLabel || '').replace(
                '{{shop}}',
                SHOPS[shop as unknown as SHOP] || '',
              )}
            </NoozText>
          ) : (
            <NoozText
              size={24}
              mb={32}
              align={'center'}>
              {staticContent.selectYourShop}
            </NoozText>
          )}
        </>
      )}
      <Box pos='relative'>
        <LoadingOverlay visible={loading} />
        <Stepper
          allowNextStepsSelect={false}
          completedIcon={
            <NoozIcon
              iconKey='Check'
              color='white'
              size={25}
            />
          }
          styles={stepperStyles}
          active={active}
          onStepClick={setActive}
          breakpoint='sm'>
          {disabledSelectShop ? null : (
            <Stepper.Step>
              <RetailBookingStepShop
                setShop={setShop}
                loading={loading}
              />
            </Stepper.Step>
          )}
          <Stepper.Step>
            <RetailBookingStepType
              setForm={setForm}
              setActive={setActive}
              loading={loading}
            />
          </Stepper.Step>
          <Stepper.Step>
            <RetailBookingStepDate
              shop={shop}
              form={form}
              data={data}
              isCurrentDay={isCurrentDay}
              setForm={setForm}
              setActive={setActive}
            />
          </Stepper.Step>
          <Stepper.Step>
            <RetailBookingStepInfo
              form={form}
              onSubmit={onSubmit}
              setForm={setForm}
              loading={loading}
            />
          </Stepper.Step>
          <Stepper.Completed>
            <RetailBookingStepResult form={form} />
          </Stepper.Completed>
        </Stepper>
      </Box>
      {active > 0 && active < 3 ? (
        <Flex
          mt={24}
          justify={'center'}>
          <NoozButton
            size='md'
            color={'title'}
            sx={{ alignSelf: 'center' }}
            disabled={loading}
            variant='outline'
            onClick={() => {
              setActive(-1, true);
            }}>
            {staticContent.backLabel}
          </NoozButton>
        </Flex>
      ) : null}
    </Container>
  );
};

export default connectBooking(RetailBooking);
