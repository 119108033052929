import format from 'date-fns/format';
import React from 'react';
import NoozNextApi from '@/utils/services/NoozNextApi';
import DataLayerHelper from '@/helpers/Datalayer';
import { createEvent, DateArray, EventAttributes } from 'ics';
import { saveAs } from 'file-saver';
import { capitalizeFirstLetter } from '@/utils';
import { PageContext } from '@/utils/context/page';
import LocaleHelper from '@/helpers/Locale';

interface RetailBookingState {
  data?: Record<string, any>;
  loading: boolean;
  form: Record<string, any>;
  isCurrentDay: boolean;
  active: number;
  language: string;
  shop: string | null | undefined;
}

export type SHOP = 'PARIS_COMMERCE' | 'WATERLOO' | 'NAMUR';
const QUERY_KEY = 'boutique';
const QUERY_MAPPING: Record<string, SHOP> = {
  'paris-commerce': 'PARIS_COMMERCE',
  waterloo: 'WATERLOO',
  namur: 'NAMUR',
};
export interface RetailBookingProps extends RetailBookingState {
  onSubmit: any;
  onAddToCalendar: any;
  setState: any;
  setForm: (value: any) => any;
  setActive: (value: number, toAdd?: boolean) => any;
  resetForm: () => void;
  onClose?: () => void;
  setShop: (shop: string) => any;
  disabledSelectShop?: boolean;
}
const connectBooking = (Component: any) => {
  return class extends React.Component<
    {
      setDrawer: any;
      onClose: any;
      shop?: SHOP;
      disabledSelectShop?: boolean;
    },
    RetailBookingState
  > {
    static contextType = PageContext;

    constructor(props: any) {
      super(props);
      this.state = {
        data: undefined,
        loading: false,
        form: {},
        isCurrentDay: false,
        active: 0,
        language: 'fr',
        shop: props.shop,
      };
    }

    componentDidMount() {
      DataLayerHelper.retailBookingInitEvent();
      if (this.props.shop) {
        this.fetchShopData();
      } else {
        const shop =
          this.state.shop ||
          QUERY_MAPPING[
            new URLSearchParams(window.location.search).get(QUERY_KEY) || ''
          ];
        this.setState({ shop });
      }
    }
    componentDidUpdate(_prevProps: {}, prevState: RetailBookingState) {
      if (prevState.shop !== this.state.shop && !!this.state.shop) {
        this.fetchShopData();
      }
    }
    fetchShopData = () => {
      if (!this.state.shop) return;

      this.setState({ loading: true });
      const language = (this.context as any)?.locale
        ? LocaleHelper.splitInfo((this.context as any)?.locale).language
        : 'en';
      this.setState((prev) => ({
        ...prev,
        loading: true,
        language,
      }));
      const api = NoozNextApi;

      api.instance
        .get('/api/odoo/appointments', {
          headers: { __nz_shop: this.state.shop },
        })
        .then(({ data }) => {
          let week = [...data.calendar]
            .splice(
              data.calendar.findIndex(
                (d: any) =>
                  d.weekDay ===
                  (data.calendar[data.calendar.length - 1].weekDay === 6
                    ? 0
                    : data.calendar[data.calendar.length - 1].weekDay + 1),
              ),
              7,
            )
            .map((el) => ({
              ...el,
              date: data.calendar[data.calendar.length - 1].date,
              slots: [el.slots?.[0]].filter((e) => !!e),
            }));
          let additionalWeeks: any[] = [];
          for (let i = 0; i < 10; ++i) {
            additionalWeeks = [...additionalWeeks, ...week];
          }
          for (let i = 0; i < additionalWeeks.length; ++i) {
            const newDate = new Date(
              data.calendar[data.calendar.length - 1].date,
            );
            newDate.setDate(newDate.getDate() + i + 1);

            additionalWeeks[i] = {
              ...additionalWeeks[i],
              day: newDate.getDate(),
              month: newDate.getMonth(),
              date: newDate.valueOf(),
              weekDay: newDate.getDay(),

              shortWeekDay: capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  weekday: 'short',
                }).formatToParts(newDate)[0].value,
              ),
              shortMonth: capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  month: 'short',
                }).formatToParts(newDate)[0].value,
              ),
              longWeekDay: capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  weekday: 'long',
                }).formatToParts(newDate)[0].value,
              ),
              longMonth: capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  month: 'short',
                }).formatToParts(newDate)[0].value,
              ),
            };
          }

          const calendar = [
            ...data.calendar.map((el: any) => {
              const date = new Date(el.date);
              const shortWeekDay = capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  weekday: 'short',
                }).formatToParts(date)[0].value,
              );
              const shortMonth = capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  month: 'short',
                }).formatToParts(date)[0].value,
              );
              const longWeekDay = capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  weekday: 'long',
                }).formatToParts(date)[0].value,
              );
              const longMonth = capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  month: 'long',
                }).formatToParts(date)[0].value,
              );

              return {
                ...el,
                shortWeekDay,
                shortMonth,
                longWeekDay,
                longMonth,
              };
            }),
            ...additionalWeeks,
          ];
          this.setState((prev) => ({
            ...prev,
            active: this.props.disabledSelectShop ? 0 : 1,
            data: {
              ...data,
              calendar,
            },
            form: {
              ...prev.form,
              date: calendar?.find((el: any) => el.slots.length > 0),
            },
          }));
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };
    resetForm = () => {
      if (!this.state.shop) return;

      this.setState((prev) => ({
        ...prev,
        form: {},
        data: undefined,
        loading: true,
        active: 0,
      }));
      DataLayerHelper.retailBookingInitEvent();
      const language = LocaleHelper.splitInfo(
        (this.context as any).locale,
      ).language;
      this.setState((prev) => ({
        ...prev,
        language,
      }));
      const api = NoozNextApi;
      api.instance
        .get('/api/odoo/appointments', {
          headers: { __nz_shop: this.state.shop },
        })
        .then(({ data }) => {
          const calendar = data.calendar.map((el: any) => {
            const date = new Date(el.date);
            const shortWeekDay = capitalizeFirstLetter(
              new Intl.DateTimeFormat(language, {
                weekday: 'short',
              }).formatToParts(date)[0].value,
            );
            const shortMonth = capitalizeFirstLetter(
              new Intl.DateTimeFormat(language, {
                month: 'short',
              }).formatToParts(date)[0].value,
            );
            const longWeekDay = capitalizeFirstLetter(
              new Intl.DateTimeFormat(language, {
                weekday: 'long',
              }).formatToParts(date)[0].value,
            );
            const longMonth = capitalizeFirstLetter(
              new Intl.DateTimeFormat(language, {
                month: 'long',
              }).formatToParts(date)[0].value,
            );

            return { ...el, shortWeekDay, shortMonth, longWeekDay, longMonth };
          });
          this.setState((prev) => ({
            ...prev,
            data: {
              ...data,
              calendar,
            },
            form: {
              ...prev.form,
              date: calendar?.find((el: any) => el.slots.length > 0),
            },
          }));
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!this.state.shop) return;

      this.setState((prev) => ({ ...prev, loading: true }));

      NoozNextApi.instance
        .post(
          '/api/odoo/appointments',
          {
            form: this.state.form,
            event: this.state.data?.type,
            appointmentType: this.state.data?.appointmentType,
            ...LocaleHelper.splitInfoWithInt((this.context as any)?.locale),
          },
          {
            headers: { __nz_shop: this.state.shop || '' },
          },
        )
        .then((res) => {
          DataLayerHelper.retailBookingConfirmationEvent();
          this.setState((prev) => ({ ...prev, active: prev.active + 1 }));
        })
        .finally(() => {
          this.setState((prev) => ({ ...prev, loading: false }));
        });
    };
    onAddToCalendar = () => {
      if (!this.state.data) return;

      const date = new Date(this.state.form.date.date);
      const shop_props: Record<string, any> = {
        PARIS_COMMERCE: {
          location: '35 Rue du Commerce 75015 Paris, France',
          url: 'https://nooz-optics.com/',
          geo: { lat: 48.8472465, lon: 2.2963146 },
        },
        WATERLOO: {
          location: '195 chaussée de Bruxelles 1410 Waterloo, Belgique',
          url: 'https://nooz-optics.com/',
          geo: { lat: 50.71609212997532, lon: 4.398566320709028 },
        },
        // TODO add Namur
        // NAMUR: {
        //   location: '43 Rue de fer 5000 Namur, Belgique',
        //   url: 'https://nooz-optics.com/',
        //   geo: { lat: 50.71609212997532, lon: 4.398566320709028 },
        // },
      };
      const event: EventAttributes = {
        start: [
          ...format(date, 'yyyy-MM-dd')
            .split('-')
            .map((e: string) => parseInt(e)),
          ...this.state.form.slot.slot
            .split(':')
            .map((e: string) => parseInt(e)),
        ] as DateArray,
        duration: {
          hours:
            this.state.data.appointmentType.appointment_duration / 1 -
            (this.state.data.appointmentType.appointment_duration % 1),
          minutes:
            (this.state.data.appointmentType.appointment_duration % 1) * 60,
        },
        title: `Nooz Opticien - ${this.state.form.type.label}`,
        description: 'Votre rendez-vous opticien Nooz',
        ...(shop_props[this.props.shop as string] || {}),
      };

      createEvent(event, (error, value) => {
        const blob = new Blob([value], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'nooz-appointment.ics');
      });
    };
    setForm = (value: any) => {
      this.setState((prev: any) => {
        const update = { ...prev.form, ...value };
        return {
          ...prev,
          form: update,
          isCurrentDay: (() => {
            if (update?.date)
              return (
                format(new Date(), 'yyyy-MM-dd') ===
                format(new Date(update.date.date), 'yyyy-MM-dd')
              );
            return false;
          })(),
        };
      });
    };
    setActive = (value: number, toAdd?: boolean) => {
      this.setState((prev: any) => ({
        ...prev,
        active: toAdd ? prev.active + value : value,
      }));
    };

    setShop = (shop: string) => {
      this.setState({ shop });
    };
    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          onSubmit={this.onSubmit}
          onAddToCalendar={this.onAddToCalendar}
          setForm={this.setForm}
          setActive={this.setActive}
          resetForm={this.resetForm}
          setShop={this.setShop}
        />
      );
    }
  };
};

export default connectBooking;
