//@ts-nocheck
import { IVariant } from '@/utils/context/product';
import {
  IGA4SelectItem,
  IGA4ViewItem,
  IGA4ViewListItem,
} from '@/helpers/interface';
import { ICartItem, ICartLine, IDataLayerItem } from '@/interface.custom';
import ShopifyBuy from 'shopify-buy';
import { BRAND } from '@/utils';
import { IProduct } from '@/@types/generated/contentful';
import {
  ShopItem,
  ShopItemProduct,
  ShopItemVariant,
} from '@/utils/context/page';

class DataLayerHelper {
  static pushDataLayerEvent(name: string, body: Gtag.EventParams) {
    if (typeof window !== 'undefined') {
      //@ts-ignore
      window.dataLayer?.push({
        event: name,
        eventModel: body,
      });
    }
  }
  static openViewedItems() {
    this.pushDataLayerEvent('open_viewed_items', {});
  }
  static clickViewedItem(product?: string) {
    this.pushDataLayerEvent('click_viewed_item', {
      //@ts-ignore
      product,
    });
  }

  static pushViewListItemsEvent(hits: ShopItem[]) {
    let viewListItemsBody: IGA4ViewListItem = {
      items: hits.map((hit: ShopItem) => ({
        index: 0,
        item_brand: BRAND,
        item_category: hit.product.categoryTitle,
        item_id: hit.variants.find((v) => v.id === hit.defaultVariantId)
          ?.shopifyId,
        parent_item_id: hit.product.shopifyId,
        item_name: hit.variants.find((v) => v.id === hit.defaultVariantId)
          ?.title,
        price: parseFloat(hit.variants[0].price.amount),
        quantity: 1,
      })),
    };

    this.pushDataLayerEvent('view_item_list', viewListItemsBody);
  }

  static pushSelectItemEvent(
    variant: ShopItemVariant,
    product: ShopItemProduct,
  ) {
    let selectItemBody: IGA4SelectItem = {
      items: [],
    };

    const item: Gtag.Item = {
      index: 0,
      item_brand: BRAND,
      item_category: product.categoryTitle,
      item_id: variant.title,
      item_name: variant.title,
      price: parseFloat(variant.price.amount),
      quantity: 1,
    };

    selectItemBody.items.push(item);

    this.pushDataLayerEvent('select_item', selectItemBody);
  }

  static pushViewItemEvent(
    product: IProduct,
    variant: IVariant,
    product_id: string,
  ) {
    const itemPrice = parseFloat(variant.shopifyInfo.price.amount);

    let viewItemBody: IGA4ViewItem = {
      currency: 'EUR',
      value: itemPrice,
      items: [],
    };

    const item: Gtag.Item & { item_id: number; parent_item_id: number } = {
      index: 0,
      item_brand: BRAND,
      item_category: product.fields.category?.fields.title,

      // @ts-ignore
      item_id: parseInt(
        variant.shopifyInfo.id.replace('gid://shopify/ProductVariant/', ''),
      ),
      parent_item_id: parseInt(
        product_id.replace('gid://shopify/Product/', ''),
      ),
      item_name: product.fields.title?.replace(/-/g, ' '),
      price: itemPrice,
      quantity: 1,
    };

    if (viewItemBody.items) viewItemBody.items.push(item);

    this.pushDataLayerEvent('view_item', viewItemBody);
  }

  static addToCartEvent(
    cartItems: ICartItem[],
    incrementalProps?: (
      _variant: ICartItem,
      _index: number,
    ) => Record<string, any>,
  ) {
    if (cartItems.length > 0) {
      const items = cartItems
        .map((item, index) => ({
          ...item.dataLayerItem,
          quantity: item.quantity,
          item_brand: BRAND,
          ...(incrementalProps ? incrementalProps(item, index) : {}),
        }))
        .reduce(
          (
            prev: { quantity: number; item_id: string; [key: string]: any }[],
            value,
          ) => {
            const pos = prev.findIndex((v) => v.item_id === value.item_id);
            if (pos >= 0) {
              prev[pos].quantity += value.quantity;
            } else {
              // @ts-ignore
              prev.push(value);
            }
            return prev;
          },
          [],
        );

      const event = {
        currency: cartItems[0].shopifyVariant.price.currencyCode,
        value: cartItems.reduce((prev: number, item) => {
          return (
            prev + item.quantity * parseFloat(item.shopifyVariant.price.amount)
          );
        }, 0),
        items,
      };

      this.pushDataLayerEvent('add_to_cart', event);

      try {
        if (typeof _klOnsite !== undefined) {
          for (const item of cartItems) {
            const kItem = {
              ProductName: item.dataLayerItem.item_name,
              ProductID: item.dataLayerItem.parent_item_id,
              VariantID: item.dataLayerItem.item_id,
              URL: window.location.href,
            };
            _klOnsite.push(['track', 'Added to cart', kItem]);
          }
        }
      } catch (e) {
        console.log('=>', e?.message);
      }
    }
  }

  static removeFromCartEvent(
    variants: ICartLine[],
    incrementalProps?: (
      _variant: ICartLine,
      _index: number,
    ) => Record<string, any>,
  ) {
    if (variants.length > 0) {
      const items = variants
        .map((v, index) => ({
          ...v.__dataLayerItem,
          quantity: v.quantity,
          item_brand: BRAND,
          ...(incrementalProps ? incrementalProps(v, index) : {}),
        }))
        .reduce(
          (
            prev: { quantity: number; item_id: string; [key: string]: any }[],
            value,
          ) => {
            const pos = prev.findIndex((v) => v.item_id === value.item_id);
            if (pos >= 0) {
              prev[pos].quantity += value.quantity;
            } else {
              // @ts-ignore
              prev.push(value);
            }
            return prev;
          },
          [],
        );
      const event = {
        currency: variants[0].variant?.price.currencyCode,
        value: items.reduce(
          (prev: number, value) => prev + value.quantity * value.price,
          0,
        ),
        items,
      };
      this.pushDataLayerEvent('remove_from_cart', event);
    }
  }
  static viewCart(cart: ShopifyBuy.Checkout) {
    const event = {
      currency: cart.currencyCode,
      value: cart.totalPrice.amount,
      items: cart.lineItems.map((line) => {
        let dataLayerItem: string | Record<string, any> =
          line.customAttributes.find((ca) => ca.key === '__dataLayerItem')
            ?.value || '{}';
        dataLayerItem = JSON.parse(dataLayerItem) as Record<string, any>;

        return {
          ...dataLayerItem,
          quantity: line.quantity,
          item_brand: BRAND,
        };
      }),
    };

    this.pushDataLayerEvent('view_cart', event);
  }

  static addToWishListEvent(
    items: { datalayerInfo: IDataLayerItem; shopify: ShopifyProductVariant }[],
  ) {
    // const event = {
    //   currency: items[0].shopify?.price.currencyCode,
    //   value: items.reduce((prev: number, item) => {
    //     return (
    //       prev +
    //       item.datalayerInfo.quantity * parseFloat(item.shopify.price.amount)
    //     );
    //   }, 0),
    //   items: items.map((i) => i.datalayerInfo),
    // };
  }

  static submitNewsletterEvent() {
    const event = {
      method: 'newsletter',
    };

    this.pushDataLayerEvent('sign_up', event);
  }

  static optionClickEvent() {
    const event = {};

    this.pushDataLayerEvent('option_click', event);
  }

  static retailBookingInitEvent(shop?: string) {
    const event = {};

    this.pushDataLayerEvent(
      `retail_booking_init${shop ? `_${shop}` : ''}`,
      event,
    );
  }

  static retailBookingConfirmationEvent() {
    const event = {};

    this.pushDataLayerEvent('retail_booking_confirmation', event);
  }
}

export default DataLayerHelper;
