import useStaticContent from '@/utils/hook/useStaticContent';
import { ActionIcon, Box, Flex, Stack } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React from 'react';

const SHOPS = [
  {
    id: 'PARIS_COMMERCE',
    label: 'Paris Commerce',
    subLabel: '35 Rue du Commerce, 75015 Paris',
  },
  {
    id: 'WATERLOO',
    label: 'Waterloo',
    subLabel: '195 Chaussée de Bruxelles, 1410 Waterloo',
  },
  // TODO add namur
  // {
  //   id: 'NAMUR',
  //   label: 'Namur',
  //   subLabel: '43 Rue de fer, 5000 Waterloo',
  // },
];

const RetailBookingStepShop = ({
  setShop,
  loading,
}: {
  setShop: any;
  loading: boolean;
}) => {
  const staticContent = useStaticContent();
  return loading ? null : (
    <Stack spacing={'1rem'}>
      <NoozText
        mt={20}
        align={'center'}
        size={24}
        weight={900}>
        {staticContent.typeTitle}
      </NoozText>
      <Stack spacing={12}>
        {SHOPS.map((el) => (
          <Box
            key={el.id}
            onClick={() => {
              setShop(el.id);
            }}
            sx={(theme) => ({
              border: `1px solid ${theme.colors.dark[6]}`,
              borderRadius: theme.radius.md,
              cursor: 'pointer',
              padding: 20,
              transition: 'background .5s',
              '&:hover': {
                background: `${theme.colors.gray[1]} !important`,
              },
            })}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 0,
              }}>
              <Flex direction='column'>
                <NoozText
                  size={18}
                  weight={700}
                  color={'title'}>
                  {el.label}
                </NoozText>
                {el.subLabel ? (
                  <NoozText
                    size={12}
                    color={'text'}>
                    {el.subLabel}
                  </NoozText>
                ) : null}
              </Flex>
              <ActionIcon
                radius='xl'
                size='xl'
                variant='outline'
                color={'text'}>
                <NoozIcon
                  iconKey={'ChevronRight'}
                  strokeWidth={1}
                  size={24}
                  color={'grey'}
                />
              </ActionIcon>
            </Box>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default RetailBookingStepShop;
